// Base colors
$color-blue-primary: #2f5891;
$color-blue-secondary: rgba(47, 88, 145, 0.67);
$color-green: #b0e088;
$color-grey: #646464;
$color-light: #f8f4f4;
$color-lightgrey: #eeeeee;
$color-white: #FFFFFF;
$color-black: #000000;
$color-red: #842029;
$color-lightblue: #7a94b7;

$color-card-grey: $color-grey;
$color-card-lightblue: $color-lightblue;
$color-card-darkblue: $color-blue-primary;
$color-card-green: $color-green;

$color-toast-bg-success: #d1e7dd;
$color-toast-success: #0f5132;
$color-toast-bg-danger: #f8d7da;
$color-toast-danger: #842029;
$color-toast-bg-info: #fae7c7;
$color-toast-info: #000000;

$color-body-background: #f0f0f0;

$color-link-hover: #0d6efd;

.bg-color-blue-primary {
    background-color: $color-blue-primary;
}

.bg-color-blue-secondary {
    background-color: $color-blue-secondary;
}

// Base color css classes
.color-lightblue {
    color: $color-lightblue;
}
.color-green {
    color: $color-green;
}
.color-red {
    color: $color-red;
}
