.templatesDirectoryTreeBorder {
  border: 1px solid black;
  border-radius: 4px;
  margin: 0 1rem;
  padding: 1rem;
}

.createNewFolderSection {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;

  &.visible {
    max-height: 400px;
    transition: max-height 0.3s ease-in;
  }
}

.disabledSection {
  position: relative;
  opacity: 0.5;
}

.disabledSection::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: gray;
  opacity: 0.5;
}