@import "contentContainer";
@import "toast";
@import "overlay";

.circle {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    font-size: 16px;
    color: $color-white;

}