.edited {
  background-color: rgba(225, 196, 100, 0.5);
}

.checklistFormContainerHeader {
  display: flex;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 8px;
}

.checklistFormContainerActionButtons {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}

.checklistFormActionButton {
  font-size: 12px;
  text-transform: uppercase;
  margin: 0 5px 5px 0;
}