.clickableLink {
  &:hover {
    cursor: pointer !important;
    opacity: 0.5 !important;
  }
}

.disabled {
  opacity: 0.5;
  &:hover {
    cursor: default;
  }
}