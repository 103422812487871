@import "src/styles/colors.scss";

.toolbarDropdownItem {
  display: flex;
  padding: 8px;
  width: 100%;
  align-items: center;
  vertical-align: middle;
  &:hover {
    background-color: $color-lightgrey;
  }
}

.toolbarItem {
  display: flex;
  min-width: 70px;
  background: none;
  border-radius: 10px;
  padding: 8px;
  align-items: center;
  vertical-align: middle;

  &:hover {
    background-color: $color-lightgrey;
  }
}

.toolbarItemActive {
  background-color: $color-lightgrey;
}

button {
  border: 0;
  display: flex;
  background: none;
  cursor: pointer;

  &:disabled {
    opacity: 0.8;
    cursor: default;
  }
}