.clickable {
  z-index: 1;
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
  :after {
    content: '';
    display: none;
  }
}