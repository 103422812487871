@import "src/styles/colors.scss";

.card {
  box-shadow: 0 2px 10px 0 rgba(180, 180, 180, 0.5);
  background-color: #C0656566 !important;
}

.header {
  color: $color-white !important;
  padding: 5px !important;
  //background-color: rgba(129, 129, 129, 0.6) !important;
  background-color: #cfe2ff !important;
  color: #084298 !important;
}

.body {
  color: $color-black !important;
  padding: 5px !important;
}


//alternativ för header
.card2 {
  box-shadow: 0 2px 10px 0 rgba(180, 180, 180, 0.5);
}

.header2 {
  color: $color-white !important;
  padding: 5px !important;
  background-color: rgba(129, 129, 129, 0.6) !important;
}

.todoColorRed {
  //background-color: rgba(192, 101, 101, 0.8) !important;
  background-color: #f8d7da !important;
  color: #842029 !important;
}

.todoColorYellow {
  //background-color: rgba(250, 190, 88, 0.8) !important;
  background-color: #ffffcc !important;
  color: #654321 !important;
}
