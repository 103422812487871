.dropdown {
  :after {
    content: none !important;
  }
}

.click {
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}