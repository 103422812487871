.toastContainer {
    padding-top: calc(9rem - 40px);
    margin-right: 1rem;
}

.toast {
    border-bottom-right-radius: 8px !important;
    border-bottom-left-radius: 8px !important;
}

.toast-header {
    border-bottom: 1px solid $color-black !important;
}

.toast-body {
    border-bottom-right-radius: 7px !important;
    border-bottom-left-radius: 7px !important;
}

.toastSuccess {
    color: $color-toast-success !important;
    background-color: $color-toast-bg-success !important;
}

.toastDanger {
    color: $color-toast-danger !important;
    background-color: $color-toast-bg-danger !important;
}

.toastInfo {
    color: $color-toast-info !important;
    background-color: $color-toast-bg-info !important;
}