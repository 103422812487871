@import "src/styles/colors.scss";

.clickableIcon {
  cursor: pointer;
  transition: .3s;
}

.clickableIconEdit {
  &:hover {
    color: $color-blue-secondary;
    filter: brightness(85%);
  }
}

.clickableIconSave {
  &:hover {
    color: $color-green;
    filter: brightness(85%);
  }
}

.clickableIconCancel {
  &:hover {
    color: red;
    filter: brightness(85%);
  }
}

.loadingSpinner {
  padding-right: 10px;
  padding-left: 7px;
  display: flex;
}