.topMenu {
    position: fixed;
    top: 0;
    z-index: 999;
    height: 100px;
    background-color: $color-white;
    transition: height 250ms;
    box-shadow: 0 2px 10px 0 rgba(180, 180, 180, 0.7);
}

.menu-container {
    margin-top: 40px;
}

.menu-container.collapsed {
    display: none;
}

.menu-container-content {
    animation: fadeIn 1s;
}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

.nav-link {
    color: black;
}

#nav-dropdown {
    color: $color-lightgrey;
}

#nav-dropdown::after {
    content: none !important;
}