.checklistTableFields {
  font-size: .8rem;
  border: none;
  border-radius: 0;
  border-left: 1px solid #eeeeee;
  border-right: 1px solid #eeeeee;
  &:focus {
    border-color: #FFFFFF;
    outline: 0;
    box-shadow: none;
  }
  &.form-control:disabled {
    background-color: #FFFFFF;
  }
}

.checklistTableTextArea {
  height: auto;
  overflow: hidden;
}

.disableResize {
  resize: none;
}

.checklistTableFieldsSelect {
  font-size: .8rem;
  &:focus {
    outline: 0;
    box-shadow: none;
    border-color: #eeeeee;
  }
  &.form-select:disabled {
    background-color: #FFFFFF;
  }
}