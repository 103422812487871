//@import "node_modules/bootstrap/scss/bootstrap";

.containerPadding {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
  //@include media-breakpoint-down(sm) {
  //  padding-right: 0 !important;
  //  > * {
  //    padding-right: 0 !important;
  //    margin-right: 0 !important;
  //  }
  //}
}
