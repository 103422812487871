.nodeRoot {
  align-items: center;
  display: flex;
  height: 36px;
  padding-inline-end: 4px;
  overflow: auto;
}

.content {
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: space-between;
}

.treeRow {
  max-height: 50rem;
  overflow: auto;
}
.documentsDirectoryTreeBorder {
  border: 1px solid black;
  border-radius: 4px;
  //margin: 0 1rem;
  //padding: 1rem;
}