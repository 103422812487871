.visible {
  @media screen and (max-height: 700px) {
    display: none;
  }
}

.devVisible {
  @media screen and (min-height: 700px) {
    display: none!important;
  }
}