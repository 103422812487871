@import "_bootstrapOverride.scss";
@import "~bootstrap/scss/bootstrap";
@import "_colors.scss";
@import "_dataTable.scss";
@import "_navbar.scss";
@import "_dashboard.scss";
@import "_incident.scss";
@import "_directory.scss";
@import "_checklistTableView.scss";

@import "./components/components";

body {
  font-family: "Outfit", "Roboto", "sans-serif";
  color: $color-grey;
  background-color: $color-body-background;
}

.main {
  //max-width: 1440px;
  min-height: calc(100vh - 8rem);
  padding-top: calc(8rem - 10px);
  padding-bottom: calc(8rem - 10px);
}

h6 {
  text-transform: uppercase;
  margin-bottom: 3px;
}

a {
  color: $color-grey;
  text-decoration: none;
}

.login {
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-tabs .nav-link {
    border-color: rgba($color-grey, .2);
}

.btn-primary {
  background-color: $color-blue-primary;
  color: $color-white;
}

.spinner-btn {
  min-width: 100px;
}

.text-as-btn {
  color: $color-black;
  cursor: pointer;

  &:hover {
    color: rgba($color-black, .5);
  }
}

.z-index9999 {
  z-index: 9999 !important;
}

.striped-table thead {
  background-color: $color-blue-primary;
  color: $color-white;
}

// change the styling of tooltipWrapper
#tooltip-top .tooltip-inner {
  background-color: $color-green;
  color: $color-black;
}

#tooltip-top > .tooltip-arrow::before {
  border-top-color: $color-green;
}

// TextModule styling.
// In the future, see if we can put these in TextModule.module.scss instead
// (.editor-paragraph/editor-heading doesn't work since it's getting a unique className in a module.scss)
.contentEditable {
  min-height: 100px;
  border: 1px solid black;
  border-radius: 0 0 10px 10px;
  padding: 10px;
}

.contentEditableReadOnly {
  border: 1px solid $color-lightgrey;
  padding: 10px;
}

[contenteditable]:focus {
  outline: 0 solid transparent;
}

.editor-paragraph {
}

.editor-heading-h1 {
}

.editor-text-bold {
  font-weight: bold;
}
.editor-text-italic {
  font-style: italic;
}
.editor-text-underline {
  text-decoration: underline;
}
.editor-text-strikethrough {
  text-decoration: line-through;
}

.editor-ordered-list1 {
  padding: 0;
  margin: 0;
  list-style-position: inside;
}

.editor-unordered-list1 {
  padding: 0;
  margin: 0;
  margin-left: 16px;
  list-style-position: inside;
  list-style: initial;
}
.editor-list-item {
  margin: 0 32px;
}
.editor-nested-list-item {
  list-style-type: none;
}
.editor-nested-list-item:before,
.editor-nested-list-item:after {
  display: none;
}

.editor-table {
  border-collapse: collapse;
  border-spacing: 0;
  max-width: 100%;
  overflow-y: scroll;
  table-layout: fixed;
  width: 100%;
  margin: 0;
}
.editor-table-cell {
  border: 1px solid #bbb;
  min-width: 75px;
  vertical-align: top;
  text-align: start;
  padding: 6px 8px;
  position: relative;
  cursor: default;
  outline: none;
}

.lexicalDropdown {
  z-index: 5;
  box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(255, 255, 255, 0.5);
  border-radius: 8px;
  min-width: 100px;
  min-height: 40px;
  background-color: #fff;
}

.lexicalDropdown .item {
  margin: 0 8px 0 8px;
  padding: 8px;
  color: #050505;
  cursor: pointer;
  line-height: 16px;
  font-size: 15px;
  display: flex;
  align-content: center;
  flex-direction: row;
  flex-shrink: 0;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 8px;
  border: 0;
  min-width: 268px;
}

.lexicalDropdown .item .active {
  display: flex;
  width: 20px;
  height: 20px;
  background-size: contain;
}

.lexicalDropdown .item:first-child {
  margin-top: 8px;
}

.lexicalDropdown .item:last-child {
  margin-bottom: 8px;
}

.lexicalDropdown .item:hover {
  background-color: #eee;
}

.lexicalDropdown .item .text {
  display: flex;
  line-height: 20px;
  flex-grow: 1;
  width: 200px;
}

.lexicalDropdown .item .icon {
  display: flex;
  width: 20px;
  height: 20px;
  user-select: none;
  margin-right: 12px;
  line-height: 16px;
  background-size: contain;
}

// ----------------