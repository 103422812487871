@import "src/styles/colors.scss";

.checklistTableWrapper {
  //padding-bottom: 1rem;
  background-color: white;
  width: 98%;
  margin: auto;

}

.checklistTable {
  position: relative;
}

.tableHeader {
  align-self: flex-start;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  //padding-left: 8px;
  //padding-right: 8px;
  //padding-top: 3px;
  width: 200px;
  //height: 44px;
  font-weight: bold;
}

.tableRow {
  border: 1px solid $color-lightgrey;
  //padding-left: 8px;
  //padding-right: 8px;
  width: 200px;

  &.extra {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}