@import "src/styles/colors.scss";

.addModuleBox {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 5rem;
  background-color: $color-lightgrey;
  color: $color-black;
  border-radius: 5px;
  transition: background-color 100ms linear;
  border: 1px solid $color-grey;

  &:hover {
    cursor: pointer;
    background-color: $color-blue-secondary;
  }
}

.offCanvasSize {
  width: 30% !important;
}

.canvasCardContainer {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
}

.canvasCard {
  position: relative;
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 15rem;
  height: 12rem;
  background-color: $color-lightgrey;
  color: $color-black;
  border-radius: 8px;
  transition: background-color 100ms linear;

  &:hover {
    cursor: pointer;
    background-color: $color-blue-secondary;
  }
}

.canvasCardIcon {
  position: absolute;
  top: 25%;
}

.canvasCardFooter {
  position: absolute;
  bottom: 1rem;
}

.clickableIcon {
  cursor: pointer;
  transition: .3s;
}

.clickableIconEdit {
  &:hover {
    color: $color-blue-secondary;
    filter: brightness(85%);
  }
}

.clickableIconSave {
  &:hover {
    color: $color-green;
    filter: brightness(85%);
  }
}

.clickableIconCancel {
  &:hover {
    color: red;
    filter: brightness(85%);
  }
}

.iconDisabled {
  color: #b6b6b6;
}