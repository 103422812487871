@import "src/styles/colors.scss";

.checklistContainer {
  background-color: $color-lightgrey;
  border-radius: 5px;
  min-height: 200px;
  padding-bottom: 20px;

  &.edited {
    background-color: rgba(225, 196, 100, 0.5);
  }
}

.checklistModuleActionButtons {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
  margin-right: 20px;
}

.actionButtonsContainer {
  display: flex;
  flex-flow: wrap;
  justify-content: space-evenly;
}

//.checklistFormContainerHeader {
//  display: flex;
//  justify-content: space-between;
//  padding-left: 16px;
//  padding-right: 16px;
//}

//.checklistFormContainerActionButtons {
//  display: flex;
//  justify-content: flex-end;
//  margin-top: 1rem;
//}