.offcanvas-body, .offcanvas-header {
  //background-color: $color-lightgrey !important;
}

.overlayWrapper {
  position: relative;
}

.overlaySpinnerDiv {
  position: absolute;
  z-index: 10;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.2);
}