//@import "~bootstrap/scss/bootstrap";

.form {
  padding-bottom: 1rem;
  //@include media-breakpoint-down(xs) {
  //  padding-right: 0 !important;
  //  > * {
  //    padding-right: 0 !important;
  //  }
  //}
}

.children {
  //@include media-breakpoint-down(md) {
  //  margin-top: auto !important;
  //  margin-bottom: auto!important;
  //}
}