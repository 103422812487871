@import "src/styles/colors.scss";

.toolbar {
  position: relative;
  display: flex;
  margin-bottom: 1px;
  padding: 4px;
  border: 1px solid black;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  vertical-align: middle;
}

.enabled {
  cursor: pointer;
}

.disabled {
  cursor: default;
}

.toolbarDropdownItem {
  display: flex;
  padding: 8px;
  width: 100%;
  align-items: center;
  vertical-align: middle;
  &:hover {
    background-color: $color-lightgrey;
  }
}

.toolbarItem {
  display: flex;
  background: none;
  border-radius: 10px;
  padding: 8px;
  align-items: center;
  vertical-align: middle;

  &:hover {
    background-color: $color-lightgrey;
  }
}

.toolbarItemActive {
  background-color: $color-lightgrey;
}

button {
  border: 0;
  display: flex;
  background: none;
  cursor: pointer;

  &:disabled {
    opacity: 0.8;
    cursor: default;
  }
}

.divider {
  width: 1px;
  background-color: #eee;
  margin: 0 4px;
}

.colorPicker {
  position: absolute;
  left: 590px;
  top: 45px;
  z-index: 2;
}