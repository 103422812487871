.dragHandleWrapper {
  cursor: move;
  position: absolute;
  transform: translateX(-50%);
  width: 24px;
  height: 32px;
}

.dragHandle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}