.cont {
  display: flex;
  width: 200px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.clickable {
  :hover {
    cursor: pointer;
    opacity: 0.8;
  }
}
.nonClickable {
  opacity: 0.5;

  :hover {
    cursor: default;
  }
}