.radioButtonGroup {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.radioButton {
  background-color: #ededed;
  position: relative;
  padding: 4px 8px;
  border: 1px solid #858585;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;

  &.disabled {
    cursor: default;
  }
}

input[type="radio"] {
  cursor: pointer;
  margin-right: 4px;
  accent-color: black;
}

input[type="radio"]:disabled {
  cursor: default;
}
