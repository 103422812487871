@import "src/styles/colors.scss";

.imageMissing {
  width: 120px;
  height: 40px;
  border: 1px solid $color-grey;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imageMissingText {
  color: $color-grey;
  font-size: 14px;
  font-weight: bold;
  font-style: italic;
}