.content {
    // padding: 16px 16px 27px 18px;
    padding: 24px 27px;
    border-radius: 7px;
    box-shadow: 0 2px 10px 0 rgba(180, 180, 180, 0.5);
    background-color: $color-white;
}

.content-theme-lightblue {
    color: $color-white  !important;
    background-color: $color-card-lightblue  !important;
}

.content-theme-darkblue {
    color: $color-white  !important;
    background-color: $color-card-darkblue  !important;
}

.content-theme-green {
    color: $color-white  !important;
    background-color: $color-card-green  !important;
}

.content-theme-grey {
    color: $color-white !important;
    background-color: $color-card-grey  !important;
}