//@import "~bootstrap/scss/bootstrap";

.container {
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  //@include media-breakpoint-down(sm) {
  //  padding-top: 100px!important;
  //}
}

.container:first-child {
  //width: 75% !important;
  //@include media-breakpoint-down(sm) {
  //  width: 100% !important;
  //}
  //@include media-breakpoint-up(xl) {
  //  width: 60% !important;
  //}
}