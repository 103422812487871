.dashboard-card-content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.dashboard-card-content-text {
    font-size: 54px;
}

.dashboard-card-title {
    font-size: 20px;
    font-weight: 500;
}

.cursor-default {
    cursor: context-menu;
}

.cursor-pointer {
    cursor: pointer;
}

.dashboard-card-clickable {
    cursor: pointer;
    transition: .3s;
    &:hover {
        filter: brightness(85%);
    }
}